/**
 * Fetches the app download url
 */
import axios, { AxiosResponse } from "axios";

const RELEASE_STAGE = process.env.GATSBY_RELEASE_STAGE;
const CONVERSION_REGEX_PRE = new RegExp(
    `^(.*${RELEASE_STAGE})(\\d{1,3})(-\\d{10})?-full.nupkg$`,
    "g",
);

const CONVERSION_REGEX = /^(.*)(-\\d{10})?-full.nupkg$/g;

const RELEASES_DOCUMENT = process.env.GATSBY_RELEASES_DOCUMENT;
const BASENAME = (() => {
    if (RELEASES_DOCUMENT) {
        const parts = RELEASES_DOCUMENT.split("/");
        return parts.slice(0, parts.length - 1).join("/");
    }
})();

export default async (): Promise<void> => {
    if (!RELEASES_DOCUMENT) {
        return;
    }

    try {
        const response: AxiosResponse<string> = await axios.get(
            RELEASES_DOCUMENT,
        );
        const nupkg = response.data.split("\n").pop()?.split(" ")[1];

        if (!nupkg) {
            return;
        }

        if (RELEASE_STAGE && nupkg.indexOf(RELEASE_STAGE) > -1) {
            const result = CONVERSION_REGEX_PRE.exec(nupkg);

            if (!result || result.length < 3) {
                return;
            }

            window.location.href = `${BASENAME}/${result[1]}.${result[2]} Setup.exe`;
            return;
        }

        const result = CONVERSION_REGEX.exec(nupkg);

        if (!result || result.length < 2) {
            return;
        }

        window.location.href = `${BASENAME}/${result[1]} Setup.exe`;
    } catch {
        // TODO: Add decent logging
    }
};
